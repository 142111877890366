import productImage2 from "../../../assets/product/product_image_2.png";
import productImage3 from "../../../assets/product/product_image_3.jpeg";
import productImage1 from "../../../assets/product/product_image_1.png";
import CommingSoonProduct from "../../../assets/product/CommingSoonProduct.jpeg";

export default {
    MENU_HEADER: [
        {name: "About Us", path: '/about'},

        {
            name: "Product",
            path: '/products',
            submenu: [
                {
                    name: "Dried Noodle",
                    path: '/product/1'
                },
                {
                    name: "Breadcrumbs",
                    path: '/products'
                },
                {
                    name: "Flour",
                    path: '/products'
                },
                {
                    name: "Dried Seasoning",
                    path: '/products'
                },
                {
                    name: "Instant Noodle",
                    path: '/products'
                }
            ]
        },
        {name: "Video Gallery", path: '/video-gallery'},
        {name: "CSR", path: '/csr'},
        {name: "Contact", path: '/contact'}
    ],
    MENU_FOOTER: [
        {name: "Home", path: '/'},
        {name: "About Us", path: '/about'},
        {name: "Product", path: '/products'},
        {name: "CSR", path: '/csr'},
        // {name : "Our Services", path : "/services"},
        //{name : "Our Clients", path : "/services"},
        {name: "Contact", path: '/contact'}
    ],
    HomePage: {
        hero: [
            {
                title: "Best Dried noodles in Indonesia!",
                description: (
                    <>
                        Kokiku is the pride of Indonesian citizens. Kokiku's dry noodles are produced from quality
                        selected ingredients <br/>through
                        rigorous selection, resulting in a healthy product for all of us.

                    </>
                ),
                button: "Show Product"
            },
            {
                title: "Best Dry Noodles in Indonesia!",
                description: (
                    <>
                        Kokiku is the pride of Indonesian citizens. Kokiku's dry noodles are produced from quality
                        selected ingredients <br/>through
                        rigorous selection, resulting in a healthy product for all of us.
                    </>
                ),
                button: "Show Product"
            },
            {
                title: "Best Dry Noodles in Indonesia!",
                description: (
                    <>
                        Kokiku is the pride of Indonesian citizens. Kokiku's dry noodles are produced from quality
                        selected ingredients <br/>through
                        rigorous selection, resulting in a healthy product for all of us.
                    </>
                ),
                button: "Show Product"
            },

        ],
        about: {
            title: "About us",
            description:
                `With a foundation dating back to 2008, we have grown into a versatile manufacturer in Indonesia`,
            more: "Show More"
        },
        product: {
            title: "Product",
            description: "Lorem ipsum sit dolor amet lorem ipsum sit dolor amet",
            more: "See All"
        },
        video: {
            title: "Video",
            description: "Immerse yourself in our world through captivating videos showcasing our products, processes, and the passion behind everything we do.",
            list: [
                {
                    title: "Ini baru mie kering yang enaknya gak main-main"
                },
                {
                    title: "Proses Pembuatan Mie Kering"
                },
                {
                    title: "Pengolahan Tepung"
                },
                {
                    title: "Pengolahan mie kering"
                }
            ],
            more: "See All"
        }
    },
    AboutPage: {
        hero: {
            title: "About Us",
            description:
                "As a trusted food producer, we are dedicated to creating high-quality products that bring a delicious experience to dining tables around the world."
        },
        history: {
            title: "History",
            description:
                'With a  foundation dating back to 2008, we have grown into a versatile manufacturer in Indonesia, starting from our initial focus on dried noodles to a wide array of products designed to meet the varied demands of the Indonesian market. Emphasizing quality, innovation, and customer satisfaction, we are continually enhancing our offerings to ensure we deliver value and convenience to our customers across the nation.'
        },
        visionMission: {
            vision: {
                title: "Vision",
                description: (
                    <>
                        <ul style={{listStyle: 'auto', lineHeight: 2.5}}>
                            <li> Lead in innovative product manufacturing for enhanced customer satisfaction.</li>
                            <li>Provide tailored solutions for Indonesian then the global markets.</li>
                        </ul>

                    </>
                )
            },
            mission: {
                title: "Mission",
                description:
                    (
                        <>
                            <ul style={{listStyle: 'auto', lineHeight: 2.5}}>
                                <li> Develop cutting-edge products that exceed customer expectations.</li>
                                <li> Uphold consistent quality, reliability, and excellence in all products.</li>
                            </ul>
                        </>
                    )
            }
        },
        philosophy: {
            title: "Philosophy",
            description: "Lorem ipsum sit dolor amet lorem ipsum sit dolor amet",
            list: [
                {
                    title: "Philosophy #1",
                    description:
                        "Envisioning a seamless and immersive event management future, we strive to be at the forefront of technological advancements. Our vision is to become the go-to platform for event organizers seeking efficiency, engagement, and success."
                },
                {
                    title: "Philosophy #2",
                    description:
                        "Envisioning a seamless and immersive event management future, we strive to be at the forefront of technological advancements. Our vision is to become the go-to platform for event organizers seeking efficiency, engagement, and success."
                },
                {
                    title: "Philosophy #3",
                    description:
                        "Envisioning a seamless and immersive event management future, we strive to be at the forefront of technological advancements. Our vision is to become the go-to platform for event organizers seeking efficiency, engagement, and success."
                },
                {
                    title: "Philosophy #4",
                    description:
                        "Envisioning a seamless and immersive event management future, we strive to be at the forefront of technological advancements. Our vision is to become the go-to platform for event organizers seeking efficiency, engagement, and success."
                }
            ]
        }
    },
    ProductPage: {
        hero: {
            title: "Product",
            description:
                "Explore our diverse culinary creations, carefully crafted to satisfy your palate and enhance your experience."
        },
        list: [
            {
                title: "Dried Noodle",
                description:
                    "Indulge in the quality of our dried noodles, meticulously prepared with superior ingredients to deliver a rich and authentic taste experience. Affordable yet uncompromising on quality, our noodles offer a consistent texture and flavor profile that is sure to delight your taste buds. Explore our diverse range of options, including 100 grams, 160 grams, 500 grams, and 1 kg variants, tailored to suit every dining occasion and preference.",
                more: "Show More",
                image: productImage2,
                link: '/product/1'
            },
            {
                title: "Breadcrumbs",
                description: `Discover our high-quality, affordable bread crumbs tailored for Indonesian HORECA, bakery, and retail needs. From retail packs to bulk options, our consistent products guarantee excellent value for all customers.`,
                more: "Show More",
                image: productImage3,
            },
            {
                title: "Flour",
                description:
                    "Explore our premium range of packed starches, offering superior quality, affordability, and consistency. Tailored to meet the specific needs of the Indonesian market, particularly in the HORECA, bakery, and retail sectors. From cornstarch to tapioca starch and dextrose monohydrate, with more product lines to enhance your culinary endeavors.",
                more: "Show More",
                image: productImage1,
            },
            {
                title: "[Upcoming] Dried Seasoning",
                description:
                    "Get ready for our upcoming line of dried seasonings, set to launch in the second half of 2024. Offering economically priced options without compromising on quality, our products guarantee consistent flavor profiles that will elevate your culinary creations. From zesty chili spices to a wide range of flavors that enhance food products, our variants are designed to cater for retail and small businesses alike. Stay tuned for a diverse selection that will bring a burst of flavor to your dishes while fitting seamlessly into your requirements.",
                more: "Show More",
                image: CommingSoonProduct,
            },
            {
                title: "[Upcoming] Instant Noodle",
                description:
                    "Get ready to embark on a new culinary adventure in the world of instant noodles! Our latest creation is currently in the making, meticulously crafted to tantalize your taste buds with its refreshing flavors and captivating aromas. Prepare to indulge in an instant noodle experience that transcends the ordinary, transforming every bite into an unforgettable journey of delectable delights.",
                more: "Show More",
                image: CommingSoonProduct,
            },
        ]
    },
    ProductDetailPage: [
        {
            id: 1,
            hero: {
                title: "Dried Noodle",
                description:
                    "Our dried noodle products provide a delectable array of options for your everyday meals, perfectly suited for crafting flavorful and effortless culinary creations."
            },
            description: (
                <>
                    <p>
                        Indulge in the quality of our dried noodles, meticulously prepared with superior ingredients to
                        deliver a rich and authentic taste experience. Affordable yet uncompromising on quality, our
                        noodles offer a consistent texture and flavor profile that is sure to delight your taste buds.
                        Explore our diverse range of options, including 100 grams, 160 grams, 500 grams, and 1 kg
                        variants, tailored to suit every dining occasion and preference.
                    </p>
                </>
            ),
            imageList: [
                {
                    title: 'mi-1',
                    img: require("../../../assets/mi-1.png")
                },
                {
                    title: 'mi-2',
                    img: require("../../../assets/mi-2.png")
                },
                {
                    title: 'mi-3',
                    img: require("../../../assets/mi-3.png")
                },
                {
                    title: 'mi-4',
                    img: require("../../../assets/mi-4.png")
                },
            ],
            list: [
                {
                    name: "Kokiku 100 g",
                    weight: "Netto 100 g",
                    isi: "Include 2 pcs",
                    dalam_dus: "1 Box: 30 packs (3 kg)",
                    img: require("../../../assets/mie-1.png")
                },
                {
                    name: "Kokiku 160 g",
                    weight: "Netto 160 g",
                    isi: "Include 3 pcs",
                    dalam_dus: "1 Box: 20 packs (3.2 kg)",
                    img: require("../../../assets/mie-4.png")
                },
                {
                    name: "Kokiku 500 g",
                    weight: "Netto 500 g",
                    isi: "Include 10 pcs",
                    dalam_dus: "1 box: 6 packs (3 kg)",
                    img: require("../../../assets/mie-3.png")
                },
                {
                    name: "Kokiku 1 kg",
                    weight: "Netto 100 g",
                    isi: "Include 2 pcs",
                    dalam_dus: "1 box: 5 packs (5 kg)",
                    img: require("../../../assets/mie-2.png")
                },
            ]
        }
    ],
    VideoGalleryPage: {
        hero: {
            title: "Video Gallery",
            description:
                "Immerse yourself in our world through captivating videos showcasing our products, processes, and the passion behind everything we do."
        },
        list: [
            {
                title: "Lorem ipsum sit dolor amet lorem ipsum sit",
                date: "26 Oktober 2023",
                desc: "Lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit"
            },
            {
                title: "Lorem ipsum sit dolor amet lorem ipsum sit",
                date: "26 Oktober 2023",
                desc: "Lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit"
            },
            {
                title: "Lorem ipsum sit dolor amet lorem ipsum sit",
                date: "26 Oktober 2023",
                desc: "Lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit"
            },
            {
                title: "Lorem ipsum sit dolor amet lorem ipsum sit",
                date: "26 Oktober 2023",
                desc: "Lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit"
            }
        ],
        page: "Pages",
        outOf: "from"
    },
    CSRPage: {
        hero: {
            title: "Corporate Social Responsibility",
            description: `We are committed to giving back to our community through sustainable initiatives in education, environmental conservation, and community development, creating a positive impact through partnerships with local organizations.`
        },
        section1: {
            title: "SMW Sehati",
            description:
                'SEHATI bermakna berempati. Dari kata Sehati terdapat kata "sehat" artinya mengutamakan kesehatan baik untuk jasmani dan rohani dan penggalan kata "hati" artinya sepenuhnya dilakukan dengan hati (tulus). Makna keseluruhan: Garudafood selalu berempati dengan sesama.'
        },
        mission: {
            description: "SEHATI memiliki misi, yaitu:",
            list: [
                "Menerapkan 'Corporate Philosophy' Garudafood, yaitu Human Values, Business Ethic, dan Unity Through Harmony.",
                "Membentuk komunitas ‘knowledge worker’ yang mampu menjadi ‘agent of change’ yang mengedepankan nilai-nilai kemanusiaan.",
                "Memberi kontribusi positif bagi peningkatan kesejahteraan masyarakat luas melalui program yang berkesinambungan di berbagai aspek (Sosial, Ekonomi dan Lingkungan)."
            ]
        },
        pillar: {
            description: "SEHATI memiliki 5 pilar program, yaitu:",
            list: [
                "Pendidikan",
                "Kesehatan",
                "Lingkungan",
                "Bantuan Kemanusiaan",
                "Pemberdayaan Masyarakat"
            ]
        },
        section2: {
            title: "Kegiatan CSR",
            description: "Lorem ipsum sit dolor amet",
            list: [
                {
                    title: "SMW Salurkan Beasiswa Pendidikan kepada Ratusan Anak Asuh",
                    date: "31 Agustus 2023"
                },
                {
                    title: "SMW Salurkan Beasiswa Pendidikan kepada Ratusan Anak Asuh",
                    date: "31 Agustus 2023"
                },
                {
                    title: "SMW Salurkan Beasiswa Pendidikan kepada Ratusan Anak Asuh",
                    date: "31 Agustus 2023"
                },
                {
                    title: "SMW Salurkan Beasiswa Pendidikan kepada Ratusan Anak Asuh",
                    date: "31 Agustus 2023"
                },
                {
                    title: "SMW Salurkan Beasiswa Pendidikan kepada Ratusan Anak Asuh",
                    date: "31 Agustus 2023"
                },
                {
                    title: "SMW Salurkan Beasiswa Pendidikan kepada Ratusan Anak Asuh",
                    date: "31 Agustus 2023"
                },
                {
                    title: "SMW Salurkan Beasiswa Pendidikan kepada Ratusan Anak Asuh",
                    date: "31 Agustus 2023"
                },
                {
                    title: "SMW Salurkan Beasiswa Pendidikan kepada Ratusan Anak Asuh",
                    date: "31 Agustus 2023"
                }
            ],
            page: "Pages",
            outOf: "from"
        }
    },
    ContactPage: {
        hero: {
            title: "Contact",
            description:
                "Please feel free to contact us if you have any questions or would like to discuss your upcoming event. Our team is ready to assist you."
        },
        information: {
            location: "Location",
            email: "E-Mail",
            callCenter: "Call Center"
        },
        form: {
            name: "Name",
            phone: "Whatsapp/Phone Number",
            email: "E-Mail",
            subject: "Subject",
            message: "Message",
            send: "Send"
        }
    },
    CSRDetailPage: {
        back: "Back to",
        title: "SMW Salurkan Beasiswa Pendidikan kepada Ratusan Anak Asuh",
        readingMinutes: "read minutes",
        post: (
            <>
                <p>
                    Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                    vulputate libero et velit interdum, ac aliquet odio mattis. Class
                    aptent taciti sociosqu ad litora torquent per conubia nostra, per
                    inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                    Class aptent taciti sociosqu ad litora torquent per conubia nostra,
                    per inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet
                    odio mattis. Class aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per inceptos himenaeos.
                </p>
                <p>
                    Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                    vulputate libero et velit interdum, ac aliquet odio mattis. Class
                    aptent taciti sociosqu ad litora torquent per conubia nostra, per
                    inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                    Class aptent taciti sociosqu ad litora torquent per conubia nostra,
                    per inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet
                    odio mattis. Class aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per inceptos himenaeos.
                </p>
                <p>
                    Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                    vulputate libero et velit interdum, ac aliquet odio mattis. Class
                    aptent taciti sociosqu ad litora torquent per conubia nostra, per
                    inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                    Class aptent taciti sociosqu ad litora torquent per conubia nostra,
                    per inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet
                    odio mattis. Class aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per inceptos himenaeos.
                </p>
                <p>
                    Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                    vulputate libero et velit interdum, ac aliquet odio mattis. Class
                    aptent taciti sociosqu ad litora torquent per conubia nostra, per
                    inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                    Class aptent taciti sociosqu ad litora torquent per conubia nostra,
                    per inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet
                    odio mattis. Class aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per inceptos himenaeos.
                </p>
                <p>
                    Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                    vulputate libero et velit interdum, ac aliquet odio mattis. Class
                    aptent taciti sociosqu ad litora torquent per conubia nostra, per
                    inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                    Class aptent taciti sociosqu ad litora torquent per conubia nostra,
                    per inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet
                    odio mattis. Class aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per inceptos himenaeos.
                </p>
                <p>
                    Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                    vulputate libero et velit interdum, ac aliquet odio mattis. Class
                    aptent taciti sociosqu ad litora torquent per conubia nostra, per
                    inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                    Class aptent taciti sociosqu ad litora torquent per conubia nostra,
                    per inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet
                    odio mattis. Class aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per inceptos himenaeos.
                </p>
                <p>
                    Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                    vulputate libero et velit interdum, ac aliquet odio mattis. Class
                    aptent taciti sociosqu ad litora torquent per conubia nostra, per
                    inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                    Class aptent taciti sociosqu ad litora torquent per conubia nostra,
                    per inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet
                    odio mattis. Class aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per inceptos himenaeos.
                </p>
            </>
        )
    },
    footer: {
        description: `PT. Samudera Marga Wisesa
Ruko Duta Gardenia Blok D/28, Jurumudi Baru, Tangerang, Banten 15124`,
        shortcutLink: "Short Link",
        callCenter: "Call Center"
    }
}
