import productImage2 from "../../../assets/product/product_image_2.png";
import productImage3 from "../../../assets/product/product_image_3.jpeg";
import productImage1 from "../../../assets/product/product_image_1.png";
import CommingSoonProduct from "../../../assets/product/CommingSoonProduct.jpeg";

export default {
    MENU_HEADER: [
        {name: "Tentang Kami", path: '/about'},
        {
            name: "Produk",
            path: '/products',
            submenu: [
                {
                    name: "Mie Kering",
                    path: '/product/1'
                },
                {
                    name: "Tepung Roti",
                    path: '/products'
                },
                {
                    name: "Tepung",
                    path: '/products'
                },
                {
                    name: "Bumbu Tabur",
                    path: '/products'
                },
                {
                    name: "Mie Instant",
                    path: '/products'
                }
            ]
        },
        {name: "Galeri Video", path: '/video-gallery'},
        {name: "CSR", path: '/csr'},
        {name: "Kontak", path: '/contact'}
    ],
    MENU_FOOTER: [
        {name: "Home", path: '/'},
        {name: "Tentang Kami", path: '/about'},
        {name: "Produk", path: '/products'},
        {name: "CSR", path: '/csr'},
        {name: "Kontak", path: '/contact'}
        // {name : "Our Services", path : "/services"},
        //{name : "Our Clients", path : "/services"},

    ],
    HomePage: {
        hero: [
            {
                title: "Mie Kering Terbaik Nusantara!",
                description: (
                    <>
                        Kokiku andalan warga Indonesia. Mie kering Kokiku diproduksi dari
                        bahan - <br/>
                        bahan pilihan yang berkualitas melalui seleksi yang ketat sehingga
                        menghasilkan produk sehat untuk kita semua.
                    </>
                ),
                button: "Lihat Produk"
            },
            {
                title: "Mie Kering Terbaik Nusantara!",
                description: (
                    <>
                        Kokiku andalan warga Indonesia. Mie kering Kokiku diproduksi dari
                        bahan - <br/>
                        bahan pilihan yang berkualitas melalui seleksi yang ketat sehingga
                        menghasilkan produk sehat untuk kita semua.
                    </>
                ),
                button: "Lihat Produk"
            },
            {
                title: "Mie Kering Terbaik Nusantara!",
                description: (
                    <>
                        Kokiku andalan warga Indonesia. Mie kering Kokiku diproduksi dari
                        bahan - <br/>
                        bahan pilihan yang berkualitas melalui seleksi yang ketat sehingga
                        menghasilkan produk sehat untuk kita semua.
                    </>
                ),
                button: "Lihat Produk"
            }
        ],
        about: {
            title: "Tentang kami",
            description:
                `Dimulai sejak tahun 2008, kami telah berkembang menjadi produsen sembako yang beragam di Indonesia, bermula dari fokus awal kami pada produksi mie kering ke berbagai produk yang dirancang untuk memenuhi berbagai kebutuhan pasar Indonesia. `,
            more: "Selengkapnya"
        },
        product: {
            title: "Produk",
            description: "Jelajahi beragam kreasi makanan kami, yang dibuat dengan cermat untuk memuaskan selera rasa dan meningkatkan pengalaman Anda.",
            more: "Lihat semua"
        },
        video: {
            title: "Video",
            description: "Rasakan diri Anda dalam dunia kami melalui video menawan yang menampilkan produk, proses, dan semangat di balik semua yang kami lakukan.",
            list: [
                {
                    title: "Ini baru mie kering yang enaknya gak main-main"
                },
                {
                    title: "Proses Pembuatan Mie Kering"
                },
                {
                    title: "Pengolahan Tepung"
                },
                {
                    title: "Pengolahan mie kering"
                }
            ],
            more: "Lihat semua"
        }
    },
    AboutPage: {
        hero: {
            title: "Tentang Kami",
            description:
                "Sebagai produsen makanan terpercaya, kami berdedikasi untuk menciptakan produk berkualitas tinggi yang menghadirkan pengalaman lezat ke meja makan di seluruh dunia."
        },
        history: {
            title: "Sejarah",
            description:
                'Dimulai sejak tahun 2008, kami telah berkembang menjadi produsen sembako yang beragam di Indonesia, bermula dari fokus awal kami pada produksi mie kering ke berbagai produk yang dirancang untuk memenuhi berbagai kebutuhan pasar Indonesia. Dengan menekankan kualitas, inovasi, dan kepuasan pelanggan, kami terus meningkatkan penawaran kami untuk memastikan kami memberikan nilai dan kenyamanan kepada pelanggan kami di seluruh negeri.'
        },
        visionMission: {
            vision: {
                title: "Visi",
                description: (
                    <>
                        <ul style={{listStyle: 'auto', lineHeight: 2.5}}>
                            <li>Memimpin dalam manufaktur produk inovatif untuk meningkatkan kepuasan pelanggan.</li>
                            <li>Menyediakan solusi yang disesuaikan untuk pasar Indonesia kemudian pasar global.</li>
                        </ul>

                    </>
                )
            },
            mission: {
                title: "Misi",
                description: (
                    <>
                        <ul style={{listStyle: 'auto', lineHeight: 2.5}}>
                            <li> Mengembangkan produk terdepan yang melebihi harapan pelanggan.</li>
                            <li>Mengutamakan kualitas, keandalan, dan keunggulan yang konsisten dalam semua produk.</li>
                        </ul>

                    </>
                )
            }
        },
        philosophy: {
            title: "Filosofi",
            description: "Lorem ipsum sit dolor amet lorem ipsum sit dolor amet",
            list: [
                {
                    title: "Filosofi #1",
                    description:
                        "Envisioning a seamless and immersive event management future, we strive to be at the forefront of technological advancements. Our vision is to become the go-to platform for event organizers seeking efficiency, engagement, and success."
                },
                {
                    title: "Filosofi #2",
                    description:
                        "Envisioning a seamless and immersive event management future, we strive to be at the forefront of technological advancements. Our vision is to become the go-to platform for event organizers seeking efficiency, engagement, and success."
                },
                {
                    title: "Filosofi #3",
                    description:
                        "Envisioning a seamless and immersive event management future, we strive to be at the forefront of technological advancements. Our vision is to become the go-to platform for event organizers seeking efficiency, engagement, and success."
                },
                {
                    title: "Filosofi #4",
                    description:
                        "Envisioning a seamless and immersive event management future, we strive to be at the forefront of technological advancements. Our vision is to become the go-to platform for event organizers seeking efficiency, engagement, and success."
                }
            ]
        }
    },
    ProductPage: {
        hero: {
            title: "Produk",
            description:
                "Jelajahi beragam kreasi makanan kami, yang dibuat dengan cermat untuk memuaskan selera rasa dan meningkatkan pengalaman Anda."
        },
        list: [
            {
                title: "Mie Kering",
                description:
                    "Nikmati kualitas dari mie kering kami, yang dipersiapkan secara cermat dengan bahan-bahan unggulan untuk memberikan pengalaman rasa yang kaya dan autentik. Terjangkau namun tetap menjaga kualitas, mie kami menawarkan tekstur yang konsisten dan profil rasa yang pastinya akan memuaskan lidah Anda. Jelajahi beragam pilihan kami, termasuk varian 100 gram, 160 gram, 500 gram, dan 1 kg, yang disesuaikan untuk setiap acara makan dan preferensi.",
                more: "Selengkapnya",
                image: productImage2,
                link: '/product/1'
            },
            {
                title: "Tepung roti",
                description:
                    "Temukan tepung roti berkualitas tinggi, terjangkau yang disesuaikan untuk kebutuhan HORECA, bakery, dan ritel di Indonesia. Mulai dari kemasan ritel hingga opsi grosir, produk kami yang konsisten menjamin nilai yang sangat baik untuk semua pelanggan.",
                more: "Selengkapnya",
                image: productImage3,
            },
            {
                title: "Tepung",
                description:
                    "Jelajahi rentang premium produk tepung kami, yang menawarkan kualitas unggul, harga terjangkau, dan konsistensi. Disesuaikan untuk memenuhi kebutuhan spesifik pasar Indonesia, terutama di sektor HORECA, bakery, dan ritel. Mulai dari tepung jagung hingga tepung tapioka dan dekstrosa monohidrat, dengan berbagai lini produk lainnya untuk meningkatkan usaha kuliner Anda.",
                more: "Selengkapnya",
                image: productImage1,
            },
            {
                title: "[Segera Datang] Bumbu Tabur",
                description:
                    "Siapkan diri untuk rangkaian bumbu kering terbaru kami, yang akan diluncurkan pada semester kedua tahun 2024. Menawarkan pilihan dengan harga terjangkau tanpa mengorbankan kualitas, produk-produk kami menjamin profil rasa yang konsisten yang akan meningkatkan kreasi kuliner Anda. Dari bumbu cabai yang beraroma pedas hingga berbagai variasi rasa yang meningkatkan produk makanan, varian kami dirancang untuk memenuhi kebutuhan ritel dan usaha kecil. Tetap terhubung untuk seleksi yang beragam yang akan memberikan ledakan rasa pada hidangan Anda dengan sempurna.",
                more: "Selengkapnya",
                image: CommingSoonProduct,
            },
            {
                title: "[Segera Datang] Mie Instant",
                description:
                    "Kini saatnya untuk mengalami sensasi baru dalam dunia mie instant! Dengan rasa yang segar dan cita rasa yang memikat, produk terbaru kami sedang dalam tahap persiapan untuk memanjakan lidah anda. Tunggu kehadirannya, dan siapan diri anda untuk menikmati pengalaman menyantap mie instan yang lebih dari sekedar makanan cepat saji biasa. Jadikan setiap suapan sebagai petualangan baru dalam mencicipi kelezatan yang tak terlupakan!.",
                more: "Selengkapnya",
                image: CommingSoonProduct,
            },
        ]
    },
    ProductDetailPage: [
        {
            id: 1,
            hero: {
                title: "Mie Kering",
                description:
                    "Produk mie kering kami menawarkan pilihan yang lezat untuk santapan sehari-hari, cocok untuk menghadirkan hidangan lezat dan mudah disiapkan."
            },
            description: (
                <>
                    <p>
                        Nikmati kualitas dari mie kering kami, yang dipersiapkan secara cermat dengan bahan-bahan
                        unggulan
                        untuk memberikan pengalaman rasa yang kaya dan autentik. Terjangkau namun tetap menjaga
                        kualitas,
                        mie kami menawarkan tekstur yang konsisten dan profil rasa yang pastinya akan memuaskan lidah
                        Anda.
                        Jelajahi beragam pilihan kami, termasuk varian 100 gram, 160 gram, 500 gram, dan 1 kg, yang
                        disesuaikan untuk setiap acara makan dan preferensi.
                    </p>
                </>
            ),
            imageList: [
                {
                    title: 'mi-1',
                    img: require("../../../assets/mi-1.png")
                },
                {
                    title: 'mi-2',
                    img: require("../../../assets/mi-2.png")
                },
                {
                    title: 'mi-3',
                    img: require("../../../assets/mi-3.png")
                },
                {
                    title: 'mi-4',
                    img: require("../../../assets/mi-4.png")
                },
            ],
            list: [
                {
                    name: "Kokiku 100 g",
                    weight: "Netto 100 g",
                    isi: "Isi 2 keping",
                    dalam_dus: "1 dus: 30 pack (3 kg)",
                    img: require("../../../assets/mie-1.png")
                },
                {
                    name: "Kokiku 160 g",
                    weight: "Netto 160 g",
                    isi: "Isi 3 keping",
                    dalam_dus: "1 dus: 20 pack (3.2 kg)",
                    img: require("../../../assets/mie-4.png")
                },
                {
                    name: "Kokiku 500 g",
                    weight: "Netto 500 g",
                    isi: "Isi 10 keping",
                    dalam_dus: "1 dus: 6 pack (3 kg)",
                    img: require("../../../assets/mie-3.png")
                },
                {
                    name: "Kokiku 1 kg",
                    weight: "Netto 100 g",
                    isi: "Isi 2 keping",
                    dalam_dus: "1 dus: 5 pack (5 kg)",
                    img: require("../../../assets/mie-2.png")
                },
            ]
        },
        {
            id: 2,
            hero: {
                title: "Tepung Roti",
                description:
                    ""
            },
            description: (
                <>
                    <p>

                    </p>
                </>
            ),
            imageList: [],
            list: []
        },
        {
            id: 3,
            hero: {
                title: "Tepung",
                description:
                    ""
            },
            description: (
                <>
                    <p>

                    </p>
                </>
            ),
            imageList: [],
            list: []
        },
        {
            id: 4,
            hero: {
                title: "Bumbu Tabur",
                description:
                    ""
            },
            description: (
                <>
                    <p>

                    </p>
                </>
            ),
            imageList: [],
            list: []
        },
        {
            id: 5,
            hero: {
                title: "Mie Instant",
                description:
                    ""
            },
            description: (
                <>
                    <p>

                    </p>
                </>
            ),
            imageList: [],
            list: []
        }
    ],
    VideoGalleryPage: {
        hero: {
            title: "Galeri Video",
            description:
                "Rasakan diri Anda dalam dunia kami melalui video menawan yang menampilkan produk, proses, dan semangat di balik semua yang kami lakukan."
        },
        list: [
            {
                title: "Lorem ipsum sit dolor amet lorem ipsum sit",
                date: "26 Oktober 2023",
                desc: "Lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit"
            },
            {
                title: "Lorem ipsum sit dolor amet lorem ipsum sit",
                date: "26 Oktober 2023",
                desc: "Lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit"
            },
            {
                title: "Lorem ipsum sit dolor amet lorem ipsum sit",
                date: "26 Oktober 2023",
                desc: "Lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit"
            },
            {
                title: "Lorem ipsum sit dolor amet lorem ipsum sit",
                date: "26 Oktober 2023",
                desc: "Lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit lorem ipsum sit dolor amet lorem ipsum sit"
            }
        ],
        page: "Halaman",
        outOf: "dari"
    },
    CSRPage: {
        hero: {
            title: "Corporate Social Responsibility",
            description:
                "Kami berkomitmen untuk memberikan kontribusi kepada masyarakat melalui inisiatif berkelanjutan di bidang pendidikan, pelestarian lingkungan, dan pengembangan masyarakat, menciptakan dampak positif melalui kemitraan dengan organisasi lokal."
        },
        section1: {
            title: "SMW Sehati",
            description:
                'SEHATI bermakna berempati. Dari kata Sehati terdapat kata "sehat" artinya mengutamakan kesehatan baik untuk jasmani dan rohani dan penggalan kata "hati" artinya sepenuhnya dilakukan dengan hati (tulus). Makna keseluruhan: Garudafood selalu berempati dengan sesama.'
        },
        mission: {
            description: "SEHATI memiliki misi, yaitu:",
            list: [
                "Menerapkan 'Corporate Philosophy' Garudafood, yaitu Human Values, Business Ethic, dan Unity Through Harmony.",
                "Membentuk komunitas ‘knowledge worker’ yang mampu menjadi ‘agent of change’ yang mengedepankan nilai-nilai kemanusiaan.",
                "Memberi kontribusi positif bagi peningkatan kesejahteraan masyarakat luas melalui program yang berkesinambungan di berbagai aspek (Sosial, Ekonomi dan Lingkungan)."
            ]
        },
        pillar: {
            description: "SEHATI memiliki 5 pilar program, yaitu:",
            list: [
                "Pendidikan",
                "Kesehatan",
                "Lingkungan",
                "Bantuan Kemanusiaan",
                "Pemberdayaan Masyarakat"
            ]
        },
        section2: {
            title: "Kegiatan CSR",
            description: "Lorem ipsum sit dolor amet",
            list: [
                {
                    title: "SMW Salurkan Beasiswa Pendidikan kepada Ratusan Anak Asuh",
                    date: "31 Agustus 2023"
                },
                {
                    title: "SMW Salurkan Beasiswa Pendidikan kepada Ratusan Anak Asuh",
                    date: "31 Agustus 2023"
                },
                {
                    title: "SMW Salurkan Beasiswa Pendidikan kepada Ratusan Anak Asuh",
                    date: "31 Agustus 2023"
                },
                {
                    title: "SMW Salurkan Beasiswa Pendidikan kepada Ratusan Anak Asuh",
                    date: "31 Agustus 2023"
                },
                {
                    title: "SMW Salurkan Beasiswa Pendidikan kepada Ratusan Anak Asuh",
                    date: "31 Agustus 2023"
                },
                {
                    title: "SMW Salurkan Beasiswa Pendidikan kepada Ratusan Anak Asuh",
                    date: "31 Agustus 2023"
                },
                {
                    title: "SMW Salurkan Beasiswa Pendidikan kepada Ratusan Anak Asuh",
                    date: "31 Agustus 2023"
                },
                {
                    title: "SMW Salurkan Beasiswa Pendidikan kepada Ratusan Anak Asuh",
                    date: "31 Agustus 2023"
                }
            ],
            page: "Halaman",
            outOf: "dari"
        }
    },
    ContactPage: {
        hero: {
            title: "Kontak",
            description:
                "Jangan ragu untuk menghubungi kami jika ada pertanyaan atau mendiskusikan acara Anda yang akan datang. Tim kami siap membantu Anda."
        },
        information: {
            location: "Lokasi",
            email: "E-Mail",
            callCenter: "Call Center"
        },
        form: {
            name: "Nama",
            phone: "Whatsapp/Nomor Telepon",
            email: "E-Mail",
            subject: "Subyek",
            message: "Pesan",
            send: "Kirim"
        }
    },
    CSRDetailPage: {
        back: "Kembali ke",
        title: "SMW Salurkan Beasiswa Pendidikan kepada Ratusan Anak Asuh",
        readingMinutes: "menit baca",
        post: (
            <>
                <p>
                    Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                    vulputate libero et velit interdum, ac aliquet odio mattis. Class
                    aptent taciti sociosqu ad litora torquent per conubia nostra, per
                    inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                    Class aptent taciti sociosqu ad litora torquent per conubia nostra,
                    per inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet
                    odio mattis. Class aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per inceptos himenaeos.
                </p>
                <p>
                    Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                    vulputate libero et velit interdum, ac aliquet odio mattis. Class
                    aptent taciti sociosqu ad litora torquent per conubia nostra, per
                    inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                    Class aptent taciti sociosqu ad litora torquent per conubia nostra,
                    per inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet
                    odio mattis. Class aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per inceptos himenaeos.
                </p>
                <p>
                    Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                    vulputate libero et velit interdum, ac aliquet odio mattis. Class
                    aptent taciti sociosqu ad litora torquent per conubia nostra, per
                    inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                    Class aptent taciti sociosqu ad litora torquent per conubia nostra,
                    per inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet
                    odio mattis. Class aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per inceptos himenaeos.
                </p>
                <p>
                    Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                    vulputate libero et velit interdum, ac aliquet odio mattis. Class
                    aptent taciti sociosqu ad litora torquent per conubia nostra, per
                    inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                    Class aptent taciti sociosqu ad litora torquent per conubia nostra,
                    per inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet
                    odio mattis. Class aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per inceptos himenaeos.
                </p>
                <p>
                    Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                    vulputate libero et velit interdum, ac aliquet odio mattis. Class
                    aptent taciti sociosqu ad litora torquent per conubia nostra, per
                    inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                    Class aptent taciti sociosqu ad litora torquent per conubia nostra,
                    per inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet
                    odio mattis. Class aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per inceptos himenaeos.
                </p>
                <p>
                    Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                    vulputate libero et velit interdum, ac aliquet odio mattis. Class
                    aptent taciti sociosqu ad litora torquent per conubia nostra, per
                    inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                    Class aptent taciti sociosqu ad litora torquent per conubia nostra,
                    per inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet
                    odio mattis. Class aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per inceptos himenaeos.
                </p>
                <p>
                    Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                    vulputate libero et velit interdum, ac aliquet odio mattis. Class
                    aptent taciti sociosqu ad litora torquent per conubia nostra, per
                    inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur adipiscing
                    elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                    Class aptent taciti sociosqu ad litora torquent per conubia nostra,
                    per inceptos himenaeos. Rorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet
                    odio mattis. Class aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per inceptos himenaeos.
                </p>
            </>
        )
    },
    footer: {
        description: `PT. Samudera Marga Wisesa
Ruko Duta Gardenia Blok D/28, Jurumudi Baru, Tangerang, Banten 15124`,
        shortcutLink: "Pintasan Link",
        callCenter: "Call Center"
    }
}
