import React, {Component, useState} from 'react';
import {Icon} from '@iconify/react';
import {Link} from 'react-router-dom';
import shopeeIcon from '../../assets/shopee_icon.png'
import tokopediaIcon from '../../assets/tokopedia_icon.png'
import {Image} from "react-bootstrap";

export default function FloatingMarketPlace() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 576)
    return (
        <>
            {
                !isMobile ?
                    <>
                        <Link to={'https://tokopedia.link/hE9kqqOENJb'} className="floating-tool" target="_blank">
                            <Image style={{width: 60}} src={tokopediaIcon}></Image>
                        </Link>
                        <Link to={'https://shopee.co.id/k1o6qvu2s5'} className="floating-tool" target="_blank">
                            <Image style={{width: 65, marginBottom: 150}} src={shopeeIcon}></Image>
                        </Link>

                    </>


                    :
                    <>
                        <Link to={'https://tokopedia.link/hE9kqqOENJb'} className="floating-tool-mobile" target="_blank">
                            <Image style={{width: 40}} src={tokopediaIcon}></Image>
                        </Link>
                        <Link to={'https://shopee.co.id/k1o6qvu2s5'} className="floating-tool-mobile" target="_blank">
                            <Image style={{width: 45, marginBottom: 120}} src={shopeeIcon}></Image>
                        </Link>

                    </>

            }

        </>
    )
}